import React from 'react';
import Helmet from 'react-helmet';

import LandingHeader from '../../../components/Landing/Section/Header';
import '../../../assets/css/landing.css';

import AboutSection from '../../../components/Landing/Section/About';
import ClientLogosSection from '../../../components/Landing/Section/ClientLogos/lndex';

import Footer from '../../../components/Shared/AdvertisementFooter';
import PDFSection from '../../../components/Landing/Section/PdfSection';
const image = require('../../../assets/images/harvard-alumnus/harvard.svg').default;
function Index() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Landing | HarvardAlumnus</title>
      </Helmet>
      <div className="landing-wrapper">
        <LandingHeader show={false} />
        <PDFSection
          filename={'harvard-alumnus'}
          image={image}
          downloadFileName={'Harvard Alumnus'}
        ></PDFSection>
        <AboutSection />
        <ClientLogosSection />
        <Footer></Footer>
      </div>
    </>
  );
}

export default Index;
